import { InfoCircleFilled } from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Descriptions,
  Empty,
  Image,
  Layout,
  Popover,
  Row,
  Spin,
  Tag,
} from "antd";

import { orderApi } from "api/order.api";
import { OrderLog } from "components/OrderLog/OrderLog";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { userStore } from "store/userStore";
import { Order, orderStatusTrans } from "types/order";
import { ProductPackage } from "types/productPackage";
import { ReceiveTypeTrans } from "types/receiverType";
import { formatNumber } from "utils";
import { getAddress } from "utils/address";
import { openPDFInNewTab, renderShippingReceiptPDF } from "utils/pdf";

const { Content } = Layout;

const OrderDetailComponent = observer(
  ({
    selectedOrder: dataResult,
    selectedPackage,
    scrollToResult = false,
  }: {
    selectedOrder?: Order;
    selectedPackage?: ProductPackage;
    scrollToResult?: boolean;
  }) => {
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState<Order>();
    const [customerId, setCustomerId] = useState<number>();
    const [visibleImage, setVisibleImage] = useState(false);
    const [search, setSearch] = useState("");
    const detailTrackingRef = useRef<HTMLDivElement>(null);
    const [lastStatus, setLastStatus] = useState("");

    useEffect(() => {
      if (dataResult?.id) {
        fetchDetail();
      } else {
        setOrderData(undefined);
      }
    }, [dataResult]);

    useEffect(() => {
      if (dataResult && scrollToResult) {
        setTimeout(() => {
          // window.scroll({ left: 0, top: 700, behavior: "smooth" });
          detailTrackingRef.current?.scrollIntoView();
        }, 200);
      }

      detectSearchCode();
    }, [dataResult, scrollToResult]);

    const detectSearchCode = () => {
      const trackingInput = document.getElementById(
        "trackingId"
      ) as HTMLInputElement;
      if (trackingInput?.value) {
        setSearch(trackingInput?.value);
      } else {
        setSearch(dataResult?.trackingCode || "");
      }
    };

    // const

    const fetchDetail = async () => {
      setLoading(true);

      const res = await orderApi.findOrderByTRK({
        code: dataResult?.trackingCode || 0,
      });
      setLoading(false);
      setOrderData(res.data.order);
      setCustomerId(res.data.customerId);
    };

    const handleExportPdf = async () => {
      const { data } = await orderApi.findOne(orderData?.id || 0);
      if (data) {
        const doc = await renderShippingReceiptPDF(data);
        if (doc) {
          openPDFInNewTab(doc);
        }
      }
    };

    return (
      <div className="detail-tracking" ref={detailTrackingRef}>
        <div id="qr_code"></div>
        <Spin spinning={loading}>
          <Content className="main-content-view">
            {orderData && (
              <Row
                justify="center"
                align="middle"
                className="detail-tracking-wrapper"
              >
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Row gutter={32} style={{ width: "90%" }} wrap={true}>
                    <Col className="order-detail" xs={24} lg={16}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 0,
                          }}
                        >
                          <p style={{ fontSize: "17px", fontWeight: "300" }}>
                            {" "}
                            Ngày giao hàng dự kiến / Scheduled Delivery Date:{" "}
                            <br />
                            {orderData?.deliveryAt ? (
                              <b style={{ fontWeight: "700" }}>
                                On or Before{" "}
                                {!!orderData?.deliveryAt &&
                                  moment
                                    .unix(orderData.deliveryAt)
                                    .format("dddd, DD MMMM YYYY")}
                              </b>
                            ) : (
                              <b style={{ fontWeight: "700" }}>Sẽ cập nhật</b>
                            )}
                          </p>
                        </div>
                        <div
                          style={{
                            marginBottom: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "end",
                          }}
                        >
                          <h1
                            style={{
                              fontSize: "30px",
                              textAlign: "right",
                              lineHeight: "100%",
                              color: "#3973b9",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                          >
                            TRK#:{" "}
                            {selectedPackage?.name || dataResult?.trackingCode}
                          </h1>
                          {userStore.token && (
                            <Popover
                              placement="left"
                              trigger={"click"}
                              content={
                                <div>
                                  {orderData?.productPackages.map(
                                    (item, index) => (
                                      <>
                                        <a
                                          href={`/order-tracking?trackingId=${item.name}`}
                                          target={"_blank"}
                                          style={{
                                            fontSize: "20px",
                                            display: "block",
                                          }}
                                          key={item.id}
                                        >
                                          #{item.name}
                                        </a>
                                      </>
                                    )
                                  )}
                                </div>
                              }
                              title="TRK Online #"
                            >
                              <Badge
                                count={orderData.productPackages.length}
                                color="#619bff"
                              >
                                <Button
                                  style={{ marginBottom: ".5em" }}
                                  hidden={orderData.productPackages.length == 0}
                                  type="primary"
                                  ghost
                                  color="#52c41a"
                                >
                                  View TRK Online
                                </Button>
                              </Badge>
                            </Popover>
                          )}

                          {userStore.token &&
                            customerId == userStore.info.id && (
                              <Button
                                block
                                icon={<InfoCircleFilled />}
                                type="primary"
                                onClick={handleExportPdf}
                              >
                                View detailed receipt
                              </Button>
                            )}
                        </div>
                      </div>

                      {orderData?.code != null ? (
                        <div style={{ backgroundColor: "#fff" }}>
                          <Row gutter={32} style={{ marginTop: "2em" }}>
                            <Col span={24}>
                              <p
                                className="tracking-label"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  marginBottom: "0px",
                                }}
                              >
                                Trạng thái đơn/Delivery status: <br />
                                {orderData.status ? (
                                  <Tag
                                    style={{
                                      fontSize: "30px",
                                      fontWeight: "bold",
                                      margin: 0,
                                      marginBottom: "0.5em",
                                      padding: "5px 10px",
                                      marginTop: "10px",
                                      marginRight: 0,
                                    }}
                                    color={orderStatusTrans[lastStatus]?.color}
                                  >
                                    {orderStatusTrans[lastStatus]?.title ||
                                      lastStatus}
                                  </Tag>
                                ) : (
                                  "--"
                                )}
                                <br />
                                {/* <p
                                hidden={!orderData?.proofImage}
                                onClick={() => setVisibleImage(true)}
                                style={{
                                  textDecoration: "underline",
                                  color: "#3973b9",
                                  cursor: "pointer",
                                }}
                              >
                                Xem ảnh xác nhận
                              </p> */}
                                <Image
                                  hidden={!orderData?.proofImage}
                                  style={{
                                    marginTop: "10px",
                                    maxWidth: "400px",
                                    width: "100%",
                                  }}
                                  // style={{ display: "none" }}
                                  src={orderData?.proofImage}
                                  preview={{
                                    visible: visibleImage,
                                    src: orderData?.proofImage,
                                    onVisibleChange: (value) => {
                                      setVisibleImage(value);
                                    },
                                  }}
                                />
                              </p>

                              <Descriptions
                                title="Chi tiết đơn hàng"
                                bordered
                                column={1}
                                labelStyle={{ width: "50%", padding: "5px" }}
                                contentStyle={{ padding: "5px" }}
                              >
                                {orderData.numOfPacks > 0 && (
                                  <Descriptions.Item label="Số kiện / Pcs:">
                                    {orderData.numOfPacks}
                                  </Descriptions.Item>
                                )}

                                {orderData.totalWeight >= 0 && (
                                  <Descriptions.Item label="Cân nặng / Wt:">
                                    {formatNumber(orderData.totalWeight)} lbs
                                  </Descriptions.Item>
                                )}

                                <Descriptions.Item label="Hình thức nhận hàng / Delivery type:">
                                  <Tag
                                    style={{ fontSize: "13px" }}
                                    color={
                                      ReceiveTypeTrans[orderData.receiveType]
                                        ?.color
                                    }
                                  >
                                    {ReceiveTypeTrans[orderData.receiveType]
                                      ?.label || orderData.receiveType}
                                  </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="Địa chỉ nhận hàng / Delivery address:">
                                  {getAddress(
                                    undefined,
                                    orderData?.district,
                                    orderData?.city,
                                    undefined
                                  )}
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: "2em",
                            padding: "5em",
                            backgroundColor: "#fff",
                          }}
                          className="box-shadow"
                        >
                          <Empty />
                        </div>
                      )}
                    </Col>
                    <Col
                      lg={8}
                      xs={24}
                      style={{
                        minHeight: "300px",
                        marginBottom: 0,
                        paddingLeft: "3em",
                      }}
                    >
                      <OrderLog
                        onUpdateTrackingStatus={(status) =>
                          setLastStatus(status)
                        }
                        selectedOrder={orderData}
                        selectedProductPackage={selectedPackage}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Outlet />
          </Content>{" "}
        </Spin>
      </div>
    );
  }
);

export default OrderDetailComponent;
