import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const roleApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/role",
      params,
    }),

  getAdminPermission: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/role/permissions",
      params,
    }),
  findOne: (roleId: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/${roleId}`,
    }),
  importPermission: (data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/permissions/import`,
      data,
      method: "post",
    }),
  importAdminPermission: (data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/permissions/import`,
      data,
      method: "post",
    }),
  updateMerchant: (data: any, roleId: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/${roleId}`,
      data,
      method: "patch",
    }),
  updateAdmin: (data: any, roleId: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role/${roleId}`,
      data,
      method: "patch",
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/role`,
      data,
      method: "post",
    }),
};
