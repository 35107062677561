import {
  Badge,
  Button,
  Col,
  Descriptions,
  Empty,
  Layout,
  Popover,
  Row,
  Spin,
  Tag,
} from "antd";

import { OrderLog } from "components/OrderLog/OrderLog";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { userStore } from "store/userStore";
import { Order } from "types/order";
import {
  ProductPackage,
  ProductPackageStatusTrans,
} from "types/productPackage";
import { ReceiveTypeTrans } from "types/receiverType";
import { formatNumber } from "utils";
import { getAddress } from "utils/address";

const { Content } = Layout;

const ProductPackageDetail = observer(
  ({
    selectedPackage: dataResult,
    selectedOrder,
    scrollToResult = false,
  }: {
    selectedPackage?: ProductPackage;
    selectedOrder?: Order;
    scrollToResult?: boolean;
  }) => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const detailTrackingRef = useRef<HTMLDivElement>(null);
    const [lastStatus, setLastStatus] = useState("");

    useEffect(() => {
      if (dataResult && scrollToResult) {
        setTimeout(() => {
          // window.scroll({ left: 0, top: 700, behavior: "smooth" });
          detailTrackingRef.current?.scrollIntoView();
        }, 200);
      }

      detectSearchCode();
    }, [dataResult, scrollToResult]);

    const detectSearchCode = () => {
      const trackingInput = document.getElementById(
        "trackingId"
      ) as HTMLInputElement;
      setSearch(trackingInput.value);
    };

    return (
      <div className="detail-tracking" ref={detailTrackingRef}>
        <Spin spinning={loading}>
          <Content className="main-content-view">
            {dataResult && (
              <Row
                justify="center"
                align="middle"
                className="detail-tracking-wrapper"
              >
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Row gutter={32} style={{ width: "90%" }} wrap={true}>
                    <Col className="order-detail" xs={24} lg={16}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 0,
                            width: "max-content",
                          }}
                        >
                          <p style={{ fontSize: "17px", fontWeight: "300" }}>
                            {" "}
                            Ngày giao hàng dự kiến / Scheduled Delivery Date:{" "}
                            <br />
                            {selectedOrder?.deliveryAt ? (
                              <b style={{ fontWeight: "700" }}>
                                On or Before{" "}
                                {!!selectedOrder?.deliveryAt &&
                                  moment
                                    .unix(selectedOrder.deliveryAt)
                                    .format("dddd, DD MMMM YYYY")}
                              </b>
                            ) : (
                              <b style={{ fontWeight: "700" }}>Sẽ cập nhật</b>
                            )}
                          </p>
                        </div>
                        <div
                          style={{
                            marginBottom: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "end",
                          }}
                        >
                          <h1
                            // hidden={!selectedOrder}
                            style={{
                              fontSize: "30px",
                              textAlign: "right",
                              lineHeight: "100%",
                              color: "#3973b9",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                          >
                            TRK#:{" "}
                            <a style={{ color: "#3973b9" }}>
                              {dataResult.name}
                            </a>
                          </h1>
                          {userStore.token && (
                            <Popover
                              placement="left"
                              trigger={"click"}
                              content={
                                <div>
                                  {selectedOrder?.productPackages.map(
                                    (item, index) => (
                                      <>
                                        <a
                                          href={`/order-tracking?trackingId=${item.name}`}
                                          target={"_blank"}
                                          style={{
                                            fontSize: "20px",
                                            display: "block",
                                          }}
                                          key={item.id}
                                        >
                                          #{item.name}
                                        </a>
                                      </>
                                    )
                                  )}
                                </div>
                              }
                              title="TRK Online #"
                            >
                              <Badge
                                count={selectedOrder?.productPackages.length}
                                color={"#619bff"}
                              >
                                <Button
                                  style={{ marginBottom: ".5em" }}
                                  hidden={
                                    !!(
                                      !selectedOrder ||
                                      selectedOrder.productPackages.length == 0
                                    )
                                  }
                                  type="primary"
                                  ghost
                                  color="#52c41a"
                                >
                                  View TRK Online
                                </Button>
                              </Badge>
                            </Popover>
                          )}
                        </div>
                      </div>

                      {dataResult?.code != null ? (
                        <div style={{ backgroundColor: "#fff" }}>
                          <Row gutter={32} style={{ marginTop: "2em" }}>
                            <Col span={24}>
                              <p
                                className="tracking-label"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  marginBottom: "0px",
                                }}
                              >
                                Trạng thái đơn/Delivery status: <br />
                                <Tag
                                  style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    margin: 0,
                                    marginBottom: "0.5em",
                                    padding: "5px 10px",
                                    marginTop: "10px",
                                    marginRight: 0,
                                  }}
                                  color={
                                    ProductPackageStatusTrans[
                                      lastStatus || dataResult?.status
                                    ]?.color
                                  }
                                >
                                  {ProductPackageStatusTrans[
                                    lastStatus || dataResult?.status
                                  ]?.label ||
                                    lastStatus ||
                                    dataResult?.status}{" "}
                                </Tag>
                                <br />
                              </p>
                              {selectedOrder && (
                                <Descriptions
                                  title="Chi tiết đơn hàng"
                                  bordered
                                  column={1}
                                  labelStyle={{ width: "50%", padding: "5px" }}
                                  contentStyle={{ padding: "5px" }}
                                >
                                  {selectedOrder?.numOfPacks > 0 && (
                                    <Descriptions.Item label="Số kiện / PCS:">
                                      {selectedOrder?.numOfPacks}
                                    </Descriptions.Item>
                                  )}

                                  {(selectedOrder.totalWeight >= 0 ||
                                    (dataResult.totalWeight &&
                                      dataResult.totalWeight >= 0)) && (
                                    <Descriptions.Item label="Cân nặng / Wt:">
                                      {formatNumber(
                                        selectedOrder?.totalWeight ||
                                          dataResult.totalWeight
                                      )}{" "}
                                      lbs
                                    </Descriptions.Item>
                                  )}

                                  {
                                    <>
                                      <Descriptions.Item label="Hình thức nhận hàng / Delivery type:">
                                        <Tag
                                          style={{ fontSize: "13px" }}
                                          color={
                                            ReceiveTypeTrans[
                                              selectedOrder?.receiveType
                                            ]?.color
                                          }
                                        >
                                          {ReceiveTypeTrans[
                                            selectedOrder?.receiveType
                                          ]?.label ||
                                            selectedOrder?.receiveType}
                                        </Tag>
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Địa chỉ nhận hàng / Delivery address:">
                                        {getAddress(
                                          undefined,
                                          selectedOrder?.district,
                                          selectedOrder?.city,
                                          undefined
                                        )}
                                        {/* {selectedOrder?.district?.nameWithType ||
                                        "" +
                                          ", " +
                                          selectedOrder?.city?.nameWithType} */}
                                      </Descriptions.Item>
                                    </>
                                  }
                                </Descriptions>
                              )}
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: "2em",
                            padding: "5em",
                            backgroundColor: "#fff",
                          }}
                          className="box-shadow"
                        >
                          <Empty />
                        </div>
                      )}
                    </Col>
                    <Col lg={8} xs={24} className={"orderLog-wrapper"}>
                      <OrderLog
                        onUpdateTrackingStatus={(status) =>
                          setLastStatus(status)
                        }
                        selectedOrder={selectedOrder}
                        selectedProductPackage={dataResult}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Outlet />
          </Content>{" "}
        </Spin>
      </div>
    );
  }
);

export default ProductPackageDetail;
