import { Empty, message } from "antd";
import { orderApi } from "api/order.api";
import OrderDetailComponent from "components/OrderDetailComponent/OrderDetailComponent";
import ProductPackageDetail from "components/ProductPackageDetail/ProductPackageDetail";
import { useEffect, useRef, useState } from "react";
import { Order } from "types/order";
import { ProductPackage } from "types/productPackage";
import CheckTrackingInputSection from "./CheckTrackingInputSection";
import "./style/style.scss";

export const CheckTrackingIdPage = ({ title }: { title: string }) => {
  const [isError, setIsError] = useState<boolean>();
  const errorRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    {
      productPackage: ProductPackage;
      order: Order;
    }[]
  >();
  const [productPackage, setProductPackage] = useState<ProductPackage>();

  useEffect(() => {
    if (isError) {
      errorRef.current?.scrollIntoView();
    }
  }, [isError]);

  const handleTracking = async (id: string) => {
    setLoading(true);
    setIsError(false);
    try {
      const res = await orderApi.findOrderByMultipleTRK({ code: id });
      setData(res.data);
      if (!res.data[0]?.order && !res.data[0]?.productPackage) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <CheckTrackingInputSection
        loading={loading}
        title={title}
        onTracking={(id) => handleTracking(id)}
      />
      <div style={{ minHeight: isError == false ? "250px" : "unset" }}>
        {isError && (
          <div
            ref={errorRef}
            style={{
              width: "100%",
              margin: "40px 0",
              textAlign: "center",
              fontSize: "32px",
              color: "#11B142",
              fontWeight: "600",
            }}
          >
            <Empty
              description={
                <div
                  style={{
                    width: "100%",

                    textAlign: "center",
                    fontSize: "25px",
                    color: "#11B142",
                    fontWeight: "600",
                  }}
                >
                  KHÔNG TÌM THẤY ĐƠN HÀNG
                </div>
              }
            />
          </div>
        )}
        {/* {data?.order && (
          <OrderDetailComponent
            scrollToResult
            selectedOrder={data.order}
            selectedPackage={data.productPackage}
          />
        )}

        {data?.productPackage && !data.order && (
          <ProductPackageDetail
            scrollToResult
            selectedOrder={data.order}
            selectedPackage={data.productPackage}
          />
        )} */}
        {data?.map((item: any, index: number) => (
          <div key={index}>
            {item?.order && (
              <OrderDetailComponent
                scrollToResult
                selectedOrder={item.order}
                selectedPackage={item.productPackage}
              />
            )}

            {item?.productPackage && !item?.order && (
              <ProductPackageDetail
                scrollToResult
                selectedOrder={item.order}
                selectedPackage={item.productPackage}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};
