import React from "react";
import AutoComplete from "react-google-autocomplete";

export interface CoordAddress {
  lat: number;
  lng: number;
  address: string;
  city?: string;
  state?: string;
  zipCode?: string;
  street?: string;
}

interface IGoogleMapAutoComplete {
  onPlaceSelected: (coordAddress: CoordAddress) => void;
  defaultBounds?: any;
}

export const GoogleMapAutoComplete = ({
  onPlaceSelected,
  defaultBounds,
}: IGoogleMapAutoComplete) => {
  const handlePlaceSelected = (place: any) => {
    if (place.geometry && place.geometry.location) {
      const addressComponents = place.address_components || [];
      const street =
        addressComponents.find((component) => component.types.includes("route"))
          ?.long_name || "";

      const city =
        addressComponents.find((component: any) =>
          component.types.includes("locality")
        )?.long_name || "";
      const state =
        addressComponents.find((component: any) =>
          component.types.includes("administrative_area_level_1")
        )?.short_name || "";
      const zipCode =
        addressComponents.find((component: any) =>
          component.types.includes("postal_code")
        )?.long_name || "";

      onPlaceSelected({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address || "",
        city,
        state,
        zipCode,
        street,
      });
    }
  };

  return (
    <div>
      <AutoComplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        options={{
          bounds: defaultBounds,
          types: ["address"],
        }}
        style={{ width: "100%" }}
        language="vi"
        onPlaceSelected={handlePlaceSelected}
      />
    </div>
  );
};
