import AppHeader from "@crema/core/AppLayout/Default/AppHeader";
import AppFooter from "@crema/core/AppLayout/components/AppFooter";
import { Empty, Layout, message } from "antd";
import { orderApi } from "api/order.api";
import OrderDetailComponent from "components/OrderDetailComponent/OrderDetailComponent";
import ProductPackageDetail from "components/ProductPackageDetail/ProductPackageDetail";
import { useRef, useState } from "react";
import { Order } from "types/order";
import { ProductPackage } from "types/productPackage";
import CheckTrackingInputSection from "./CheckTrackingInputSection";
import "./style/style.scss";

export interface IOrderTracking {
  productPackage: ProductPackage;
  order: Order;
}

export const CheckTrackingIdPageNotLogin = ({ title }: { title: string }) => {
  const [data, setData] = useState<
    {
      productPackage: ProductPackage;
      order: Order;
    }[]
  >();
  const errorRef = useRef<HTMLDivElement>(null);
  const [isError, setIsError] = useState<boolean>();
  const [loading, setLoading] = useState(false);

  const [isTrackingId, setIsTrackingId] = useState(false);

  const handleTracking = async (id: string) => {
    setLoading(true);
    setIsError(false);
    if (id.includes("NT")) {
      setIsTrackingId(true);
    } else {
      setIsTrackingId(false);
    }
    try {
      console.log({ id });
      const res = await orderApi.findOrderByMultipleTRK({ code: id });
      setData(res.data);
      if (!res.data[0]?.order && !res.data[0]?.productPackage) {
        message.error("Không tìm thấy đơn hàng");
        setIsError(true);
      } else {
        setIsError(false);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Layout className="app-layout-main">
        <AppHeader onToggleSidebar={() => ""} />
      </Layout>

      <CheckTrackingInputSection
        loading={loading}
        title={title}
        onTracking={(id) => handleTracking(id)}
      />

      <div style={{ minHeight: isError == false ? "250px" : "unset" }}>
        {isError && (
          <div
            ref={errorRef}
            style={{
              width: "100%",
              margin: "40px 0",
              textAlign: "center",
              fontSize: "32px",
              color: "#11B142",
              fontWeight: "600",
            }}
          >
            <Empty
              description={
                <div
                  style={{
                    width: "100%",

                    textAlign: "center",
                    fontSize: "25px",
                    color: "#11B142",
                    fontWeight: "600",
                  }}
                >
                  KHÔNG TÌM THẤY ĐƠN HÀNG
                </div>
              }
            />
          </div>
        )}

        {data?.map((item: any, index: number) => (
          <div key={index}>
            {item?.order && isTrackingId && (
              <OrderDetailComponent
                scrollToResult
                selectedOrder={item.order}
                selectedPackage={item.productPackage}
              />
            )}

            {item?.productPackage && !isTrackingId && (
              <ProductPackageDetail
                scrollToResult
                selectedOrder={item.order}
                selectedPackage={item.productPackage}
              />
            )}
          </div>
        ))}

        {/* {data?.order && isTrackingId && (
          <OrderDetailComponent
            scrollToResult
            selectedOrder={data.order}
            selectedPackage={data.productPackage}
          />
        )}

        {data?.productPackage && !isTrackingId && (
          <ProductPackageDetail
            scrollToResult
            selectedOrder={data.order}
            selectedPackage={data.productPackage}
          />
        )} */}
      </div>

      <AppFooter />
    </>
  );
};
