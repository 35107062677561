import { Order } from "./order";
import { Staff } from "./staff";

export interface FlyTrip {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  code: string;
  name: string;
  startAt: number; //thời gian dự kiến bay
  endAt: number; //thời gian dự kiến đến
  status: FlyTripStatus;
  // date: string; //ngày bay
  orders: Order[];
  createdStaff: Staff;
}

export enum FlyTripStatus {
  Pending = "PENDING", //Đã tạo mới
  PrepareFly = "PREPARE_FLY",
  Fly = "FLY", //đã bay
  Arrival = "ARRIVAL", //đã đáp
  SaveVN = "SAVE_VN", //đã về tới kho hàng ở vn
  Draff = "DRAFF", //bản nháp
}

export const flyTripTrans = {
  [FlyTripStatus.Pending]: {
    color: "cyan",
    title: "Đã tạo mới",
    value: FlyTripStatus.Pending,
  },
  [FlyTripStatus.PrepareFly]: {
    color: "#f7b011",
    title: "Chuẩn bị bay",
    value: FlyTripStatus.PrepareFly,
  },
  [FlyTripStatus.Fly]: {
    color: "lime",
    title: "Đã bay",
    value: FlyTripStatus.Fly,
  },
  [FlyTripStatus.Arrival]: {
    color: "gray",
    title: "Đã đáp",
    value: FlyTripStatus.Arrival,
  },
  [FlyTripStatus.Draff]: {
    color: "#f1c232",
    title: "Bản nháp",
    value: FlyTripStatus.Draff,
  },
  [FlyTripStatus.SaveVN]: {
    color: "orange",
    title: "Đã về kho VN",
    value: FlyTripStatus.SaveVN,
  },
};
