import {
  FundViewOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Image, message, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { useState } from "react";
import { getToken } from "utils/auth";
import { $url } from "utils/url";

interface SingleImageUploadProps {
  uploadUrl?: string;
  imageUrl: string;
  accepctFile?: string;
  onUploadOk: (path: string) => void;
  onBefore?: () => Promise<boolean>;
  imageObjectFit?: "cover" | "contain";
}

export const SingleImageUpload = ({
  uploadUrl = process.env.REACT_APP_API_URL + "/v1/customer/image/upload",
  imageUrl,
  accepctFile,
  onUploadOk,
  onBefore,
  imageObjectFit,
}: SingleImageUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const beforeUpload = async (file: File) => {
    let isValid = true;
    if (onBefore) {
      isValid = await onBefore?.();
    }

    const isImg = file.type.includes("image") || file.type.includes("pdf");
    if (!isImg) {
      message.error("Only accept image format");
    }

    return isValid && isImg ? true : Upload.LIST_IGNORE;
  };
  const handleChange = (info: UploadChangeParam<any>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      onUploadOk(process.env.REACT_APP_API_URL + info.file.response.data.path);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        name="file"
        accept={accepctFile}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={uploadUrl}
        headers={{
          token: getToken() || "",
        }}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img
            src={$url(imageUrl)}
            alt="avatar"
            style={{ width: "100%", height: "100%", objectFit: imageObjectFit }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {imageUrl && (
        <>
          <Button
            type="link"
            icon={<FundViewOutlined />}
            onClick={() => setVisible(true)}
          >
            Xem ảnh
          </Button>
          <Image
            className="preview-image"
            style={{ display: "none" }}
            src={$url(imageUrl)}
            preview={{
              visible,
              src: $url(imageUrl),
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </>
      )}
    </>
  );
};
