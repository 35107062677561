import { roleApi } from "api/role.api";
import { action, makeAutoObservable, toJS } from "mobx";
import { makePersistable } from "mobx-persist-store";
// import { adminRoutes, Route } from "router";
import { settings } from "setting";
// import { settings } from "settings";
import { Permission } from "types/role";

class PermissionStore {
  permissions: Permission[] = [];
  // accessRoutes: Route[] = [];
  accessRoutes: any[] = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "PermissionStore",
      properties: ["permissions"],
      storage: localStorage,
    });
  }

  fetchPermissions = async (roleId: number) => {
    const res = await roleApi.findOne(roleId);
    console.log("permission fetch", res.data.permissions);

    this.permissions = res.data.permissions;
  };

  setAccessRoutes = () => {
    if (settings.checkPermission && this.permissions.length) {
      for (const route of this.accessRoutes) {
        if (!route.children) {
          const find = permissionStore.permissions.find(
            (e) => e.path.indexOf(route.path) > -1
          );
          route.isAccess = !!find;
        } else {
          for (const childRoute of route.children) {
            const find = permissionStore.permissions.find(
              (e) => e.path == route.path + "/" + childRoute.path
            );
            childRoute.isAccess = !!find;
          }
          route.isAccess = route.children.some((e: any) => e.isAccess);
        }
      }
    }
    console.log("setAccessRoutes ", toJS(this.accessRoutes));
  };
}

const permissionStore = new PermissionStore();

export { permissionStore };
