const chuSo = {
  0: "Khong",
  1: "Mot",
  2: "Hai",
  3: "Ba",
  4: "Bon",
  5: "Nam",
  6: "Sau",
  7: "Bay",
  8: "Tam",
  9: "Chin",
};

export const docSoBangChu = (soCanDoc: string) => {
  // Z0587 => ZKhongNamTamBay
  let result = "";
  const stringArr = soCanDoc.split("");
  stringArr.forEach((item) => {
    if (Number(item) >= 0) {
      result += chuSo[item];
    } else {
      result += item;
    }
  });

  return result;
};
