import { Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "antd/lib/form";
import FormItem from "antd/lib/form/FormItem";
import { useAddress } from "hooks/useAddress";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { AddressData } from "types/address";
import { Receiver } from "types/receiver";
import { Region } from "types/region";
const rules: Rule[] = [{ required: true }];

export interface AddressParam {
  parentCode?: string;
}

export interface AddressSelect {
  getValues: () => void;
}

export const AddressSelect = React.forwardRef(
  (
    {
      form,
      selectedReceiver,
      onChange,
    }: {
      form: FormInstance<any>;
      selectedReceiver?: Receiver;
      onChange: (data: any) => void;
    },
    ref
  ) => {
    const [queryWard, setQueryWard] = useState<AddressParam>();
    const [queryDistrict, setQueryDistrict] = useState<AddressParam>();
    const [isRequireDistrict, setIsRequireDistrict] = useState<boolean>(false);

    useEffect(() => {
      if (selectedReceiver) {
        setQueryDistrict({ parentCode: selectedReceiver?.city?.code });
        setQueryWard({ parentCode: selectedReceiver?.district?.code });
      }
    }, [selectedReceiver]);

    useImperativeHandle(
      ref,
      () => {
        return {
          async getValues() {},
        };
      },
      []
    );

    const {
      cities,
      districts,
      loading,
      wards,
      fetchCity,
      fetchDistrict,
      fetchWard,
      clearDistrict,
      clearWard,
    } = useAddress();

    useEffect(() => {
      if (queryDistrict?.parentCode) {
        fetchDistrict(queryDistrict);
      }
    }, [queryDistrict]);

    useEffect(() => {
      if (queryWard?.parentCode) {
        fetchWard(queryWard);
      }
    }, [queryWard]);

    useEffect(() => {
      fetchCity();
    }, []);

    const handleChangeCity = (cityId: number) => {
      form.resetFields(["wardId", "districtId"]);
      if (cityId) {
        const find = cities.find((e) => e.id == cityId);
        const code = find?.code;
        setQueryDistrict({ parentCode: code });
        setIsRequireDistrict(find?.region == Region.SaiGon);
      } else {
        clearDistrict();
        setIsRequireDistrict(false);
      }
    };

    const handleChangeDistrict = (districtId: number) => {
      form.resetFields(["wardId"]);
      if (districtId) {
        const parentCode = districts.find((e) => e.id == districtId)?.code;
        setQueryWard({ parentCode });
      } else {
        clearWard();
      }
    };

    const handleSubmitAddress = (value: number) => {
      if (value) {
        const { districtId, cityId, wardId } = form.getFieldsValue();
        const data: AddressData = {
          district: districts.find((e) => e.id == districtId),
          ward: wards.find((e) => e.id == wardId),
          city: cities.find((e) => e.id == cityId),
        };
        onChange(data);
      } else {
        onChange(undefined);
      }
    };

    return (
      <>
        {/* <Form form={form} layout="vertical"> */}
        <FormItem rules={rules} required label="Địa chỉ" name={"vnAddress"}>
          <Input />
        </FormItem>
        <FormItem rules={rules} required label="Tỉnh/Thành phố" name={"cityId"}>
          <Select
            onChange={(id) => {
              handleChangeCity(id);
              handleSubmitAddress(id);
            }}
            style={{ width: "100%" }}
            onClear={() => {
              clearDistrict();
              clearWard();
            }}
            allowClear
            placeholder="Nhập tên tỉnh/thành phố"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {cities?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          rules={isRequireDistrict ? rules : undefined}
          required={isRequireDistrict}
          label="Quận/Huyện"
          name={"districtId"}
        >
          <Select
            disabled={!districts.length}
            onClear={clearWard}
            onChange={(id) => {
              handleChangeDistrict(id);
              handleSubmitAddress(id);
            }}
            style={{ width: "100%" }}
            allowClear
            placeholder="Nhập tên Quận/Huyện"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {districts?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="Phường/Xã/Thị trấn" name={"wardId"}>
          <Select
            disabled={!wards.length}
            style={{ width: "100%" }}
            allowClear
            onChange={handleSubmitAddress}
            placeholder="Nhập tên Phường/Xã/Thị trấn"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {wards?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        {/* </Form> */}
      </>
    );
  }
);
