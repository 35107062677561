import { Space, Tag, Timeline } from "antd";
import { orderLogApi } from "api/orderLog.api";
import { PostCodeComponent } from "components/PostCodeComponent/PostCodeComponent";
import { useEffect, useState } from "react";
import {
  Order,
  OrderLog as IOrderLog,
  OrderStatus,
  orderStatusTrans,
} from "types/order";
import { ProductPackage } from "types/productPackage";
import { unixToFullDate } from "utils/formatDate";
import { getRandomLetters } from "utils/uniqueKey";

export const OrderLog = ({
  selectedOrder,
  selectedProductPackage,
  onUpdateTrackingStatus,
}: {
  selectedOrder?: Order;
  selectedProductPackage?: ProductPackage;
  onUpdateTrackingStatus: (status: string) => void;
}) => {
  const [orderLog, setOrderLog] = useState<IOrderLog[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newOrderLog = orderLog
      .filter((e) => e.status && e.isVisible)
      .sort((a, b) => a.createdTime - b.createdTime);
    onUpdateTrackingStatus(newOrderLog?.[newOrderLog.length - 1]?.status);
  }, [orderLog]);

  useEffect(() => {
    if (selectedOrder) {
      fetchOrderLog();
    } else {
      setOrderLog([
        {
          //@ts-ignores
          id: getRandomLetters(10),
          status: OrderStatus.SaveUsa,
          createdTime: selectedProductPackage?.createdAt || 0,
          createdAt: selectedProductPackage?.createdAt || 0,
          isVisible: !selectedProductPackage?.isHiddenFirstStatus,
        },
      ]);
    }
  }, [selectedOrder, selectedProductPackage]);

  const fetchOrderLog = async () => {
    setLoading(true);
    const res = await orderLogApi.findOne({ orderId: selectedOrder?.id });

    let orderLogs: IOrderLog[] = res.data.orderLogs;
    //Cập nhật đơn đã tạo label thành thời gian khách chọn
    orderLogs.forEach((item) => {
      if (item.status == OrderStatus.Labeled) {
        item.createdTime = selectedOrder?.createdDateTime;
      }
    });

    orderLogs = handleDuplicateLog(orderLogs);

    if (selectedProductPackage) {
      const saveUSADate = selectedProductPackage.createdAt;
      //

      orderLogs = orderLogs.filter((item) => item.createdTime >= saveUSADate);
      orderLogs.unshift({
        //@ts-ignore
        id: getRandomLetters(10),
        status: OrderStatus.SaveUsa,
        createdTime: saveUSADate,
        createdAt: saveUSADate,
        isVisible: !selectedProductPackage?.isHiddenFirstStatus,
      });
    }

    setOrderLog([...orderLogs]);
  };

  const handleDuplicateLog = (orderLogs: IOrderLog[]) => {
    // Bỏ trạng thái label bị dup => lấy cái mới nhất

    let newOrderLogs = orderLogs;

    const labeledItems = orderLogs.filter(
      (item) => item.status == OrderStatus.Labeled
    );

    if (labeledItems.length > 1) {
      const sortedItems = labeledItems.sort(
        (a, b) => a.createdTime - b.createdTime
      );
      const removeItems = sortedItems.slice(1);
      const removeItemIds = removeItems.map((item) => item.id);
      console.clear();

      newOrderLogs = orderLogs.filter(
        (item) => !removeItemIds.includes(item.id)
      );
    }
    return newOrderLogs;
  };

  return (
    <div>
      <Timeline className="orderLog-timeline">
        {orderLog &&
          orderLog
            .filter((e) => e.status && e.isVisible)
            .sort((a, b) => a.createdTime - b.createdTime)
            .map((item, index) => (
              <Timeline.Item
                key={index}
                dot={
                  <img
                    className="custom-icon"
                    src={orderStatusTrans[item.status]?.icon}
                    width={25}
                  />
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "4px",
                    padding: "6px 10px ",
                    backgroundColor:
                      index == orderLog.length - 1 ? "#3973b9" : "white",
                    color: index == orderLog.length - 1 ? "#fff" : "unset",
                    transition: "all ease-in-out 0.2s",
                  }}
                >
                  <Space>
                    <Tag color={orderStatusTrans[item.status]?.color}>
                      <Space size={0} direction={"vertical"}>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          {orderStatusTrans[item.status]?.title || item.status}
                        </span>
                        {item.status == OrderStatus.DeliveryEMS && (
                          <>
                            {selectedOrder?.postCode ? (
                              <PostCodeComponent
                                postCode={selectedOrder.postCode}
                              />
                            ) : (
                              <span>Mã bưu gửi: --</span>
                            )}
                          </>
                        )}
                      </Space>
                    </Tag>
                  </Space>
                  <span style={{ fontSize: "14px" }}>
                    {item.createdTime && unixToFullDate(item.createdTime)}
                  </span>
                </div>
              </Timeline.Item>
            ))}
      </Timeline>
    </div>
  );
};
