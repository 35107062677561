import { ClockCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Col, Descriptions, Empty, Layout, Row, Timeline } from "antd";
import { OrderStatusTag, PaymentTypeTag } from "components/Tags/Tags";
import moment from "moment";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { settings } from "setting";
import { Order, orderStatusTrans, PaymentType } from "types/order";
import { formatNumber, formatVND } from "utils";
import { AppSuspense } from "../../index";
import "./index.style.less";

const { Content } = Layout;

const AppContentView = ({ dataResult }: { dataResult?: Order }) => {
  // const { user, isAuthenticated } = useAuthUser();
  const [currentTimeline, setCurrentTimeline] = useState(0);

  useEffect(() => {
    dataResult && window.scroll({ left: 0, top: 700, behavior: "smooth" });
  }, [dataResult]);

  return (
    <Content className="main-content-view">
      <AppSuspense>
        {/* <AppErrorBoundary>
          {generateRoutes({
            isAuthenticated: isAuthenticated,
            userRole: user?.role,
            unAuthorizedStructure,
            authorizedStructure,
            anonymousStructure,
          })}
          <Routes>
            <Route path="/" element={<Navigate to={initialUrl} replace />} />
          </Routes>
        </AppErrorBoundary> */}
        {/* <Routes>
          <Route path="/" element={<Navigate to={initialUrl} replace />} />
        </Routes> */}
        {dataResult && (
          <Row
            justify="center"
            align="middle"
            style={{ width: "100%", padding: "70px 0 0" }}
          >
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Row gutter={[16, 16]} style={{ width: "90%" }} wrap={true}>
                <Col
                  xs={24}
                  lg={16}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Row gutter={260}>
                    <Col style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ fontSize: "13px", fontWeight: "700" }}>
                        UPDATED DELIVERY
                      </p>
                      <p style={{ fontSize: "30px" }}>
                        {!!dataResult?.deliveryAt &&
                          moment.unix(dataResult.deliveryAt).format("dddd")}
                      </p>
                      <p style={{ fontSize: "17px", fontWeight: "300" }}>
                        {!!dataResult?.deliveryAt &&
                          moment
                            .unix(dataResult.deliveryAt)
                            .format("MM/DD/YYYY")}
                      </p>
                    </Col>
                    <Col>
                      <p style={{ fontSize: "17px" }}>
                        Delivery Status:{" "}
                        {(dataResult &&
                          orderStatusTrans[dataResult.status]?.title) ||
                          dataResult.status}
                      </p>
                    </Col>
                  </Row>
                  {dataResult && dataResult.status === "DELAY" && (
                    <button
                      style={{
                        border: "2px solid red",
                        width: "100px",
                        backgroundColor: "#fff",
                        borderRadius: "4px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "red",
                        marginBottom: "20px",
                      }}
                    >
                      Delayed
                    </button>
                  )}
                  {dataResult?.code != null ? (
                    <div style={{ backgroundColor: "#fff" }}>
                      <Row gutter={32} style={{ marginTop: "2em" }}>
                        <Col span={12} style={{ marginBottom: "0" }}>
                          <p className="ant-descriptions-title">Người gửi</p>
                          <p>{dataResult?.senderName}</p>
                          <p>{dataResult?.senderPhone}</p>
                          <p>{dataResult?.senderName}</p>
                        </Col>

                        <Col
                          span={12}
                          style={{
                            marginBottom: "0",
                            borderLeft: "1px solid #efefef",
                          }}
                        >
                          <p className="ant-descriptions-title">Người Nhận</p>
                          <p>{dataResult?.receiverName}</p>
                          <p>{dataResult?.receiverPhone}</p>
                          <p>{dataResult?.receiverAddress}</p>
                        </Col>
                        <Col span={24}>
                          <Descriptions
                            title="Chi tiết đơn hàng"
                            bordered
                            column={1}
                            labelStyle={{ width: "50%", padding: "5px" }}
                            contentStyle={{ padding: "5px" }}
                          >
                            <Descriptions.Item label="Tracking ID">
                              {dataResult?.trackingCode}
                            </Descriptions.Item>
                            <Descriptions.Item label="Trạng thái đơn hàng">
                              {dataResult?.status && (
                                <OrderStatusTag status={dataResult?.status} />
                              )}
                            </Descriptions.Item>

                            <Descriptions.Item label="Giao hàng lúc (Dự kiến)">
                              {dataResult && dataResult?.deliveryAt > 0
                                ? moment
                                    .unix(dataResult.deliveryAt)
                                    .format(settings.dateFormat)
                                : "--"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Hình thức thanh toán">
                              {dataResult?.paymentType && (
                                <PaymentTypeTag type={dataResult.paymentType} />
                              )}
                            </Descriptions.Item>
                            {dataResult?.paymentType == PaymentType.COD && (
                              <Descriptions.Item label="Người nhận trả">
                                {formatNumber(dataResult?.codVND || 0)}đ
                              </Descriptions.Item>
                            )}

                            <Descriptions.Item label="Ghi chú">
                              {dataResult?.note}
                            </Descriptions.Item>
                            <Descriptions.Item
                              style={{ fontSize: "20px" }}
                              labelStyle={{ fontWeight: "500" }}
                              label="Tổng tiền"
                            >
                              <b>{formatVND(dataResult?.moneyFinal || 0)}</b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "2em",
                        padding: "5em",
                        backgroundColor: "#fff",
                      }}
                      className="box-shadow"
                    >
                      <Empty />
                    </div>
                  )}
                </Col>
                <Col lg={8} xs={24} style={{ minHeight: "300px" }}>
                  <Timeline>
                    {dataResult &&
                      dataResult.orderLogs.map((item, index) => (
                        <Timeline.Item
                          key={index}
                          dot={
                            currentTimeline === index ? (
                              <ClockCircleFilled
                                style={{
                                  fontSize: "20px",
                                  transition: "all ease-in-out 0.3s",
                                }}
                              />
                            ) : (
                              <ExclamationCircleFilled
                                style={{
                                  fontSize: "20px",
                                  transition: "all ease-in-out 0.3s",
                                }}
                                onClick={() => setCurrentTimeline(index)}
                              />
                            )
                          }
                          color={currentTimeline === index ? "#7171fa" : "aqua"}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "4px",
                              padding: "0 10px 6px 10px",
                              backgroundColor:
                                currentTimeline === index ? "#e3e3ff" : "white",
                              cursor: "pointer",
                              transition: "all ease-in-out 0.2s",
                            }}
                            onClick={() => setCurrentTimeline(index)}
                          >
                            <span style={{ fontSize: "16px", fontWeight: 600 }}>
                              {item.description}
                            </span>
                            <span style={{ fontSize: "14px" }}>
                              {orderStatusTrans[item.status]?.title ||
                                item.status}
                            </span>
                            <span style={{ fontSize: "14px" }}>
                              {dataResult.createdAt &&
                                moment
                                  .unix(dataResult.createdAt)
                                  .subtract(1, "days")
                                  .calendar()}
                            </span>
                          </div>
                        </Timeline.Item>
                      ))}
                  </Timeline>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Outlet />
      </AppSuspense>
    </Content>
  );
};

export default AppContentView;
