import { Pagination as AntPagination } from "antd";

interface IPagination {
  total: number;
  onChange: ({ page, limit }: { page: number; limit: number }) => void;
  currentPage: number;
  pageSize?: number;
  showTotal?: boolean;
}

export const PaginationLite = ({
  total,
  onChange,
  currentPage,
  pageSize = 10,
  showTotal = true,
}: IPagination) => {
  return (
    <AntPagination
      simple
      size="small"
      current={currentPage}
      // style={{ marginTop: 12 }}
      total={total}
      showSizeChanger={false}
      onChange={(page, limit) => {
        onChange({
          page,
          limit,
        });
      }}
      onShowSizeChange={(limit) => {
        onChange({
          page: currentPage,
          limit,
        });
      }}
      pageSize={pageSize}
      showTotal={(total) => {
        if (showTotal) {
          return `Tổng ${total} dòng`;
        }
      }}
    />
  );
};
