import { Menu } from "antd";
import { toJS } from "mobx";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { settings } from "setting";

import { useSidebarContext } from "./AppContextProvider/SidebarContextProvider";

function getStyles(item, sidebarColorSet, isSidebarBgImage, index, isGroup) {
  const { pathname } = useLocation();
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/");

  // if (index === 0 || isGroup) {
  //   return {
  //     color: sidebarColorSet.sidebarTextColor,a
  //     backgroundColor: isSidebarBgImage ? "" : sidebarColorSet.sidebarBgColor,
  //   };
  // } else {
  const isActive = "/" + defaultOpenKeys[index] === item.path;

  return {
    color: isActive
      ? sidebarColorSet.sidebarMenuSelectedTextColor
      : sidebarColorSet.sidebarTextColor,
    borderRight: isActive ? "2px solid #0a8fdc" : "none",
    backgroundColor: isActive
      ? sidebarColorSet.sidebarMenuSelectedBgColor
      : isSidebarBgImage
        ? ""
        : sidebarColorSet.sidebarBgColor,
  };
  // }
}

const renderMenuItemChildren = (item) => {
  const { icon, title, path } = item;

  if (path && path.includes("/"))
    return (
      <Link to={path}>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className="ant-menu-item-icon">{icon}</span>
          ) : (
            <icon className="ant-menu-item-icon" />
          ))}
        <span data-testid={title.toLowerCase + "-nav"}>{item.title}</span>
      </Link>
    );
  else {
    return (
      <>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className="ant-menu-item-icon">{icon}</span>
          ) : (
            <icon className="ant-menu-item-icon" />
          ))}
        <span data-testid={"-nav"}>{item.title}</span>
      </>
    );
  }
};

const renderMenuItem = (item, sidebarColorSet, isSidebarBgImage, index) => {
  return item.type === "collapse" ? (
    <Menu.SubMenu
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
      key={item.path ? item.path : item.id}
      title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
    >
      {item.children.map((item) =>
        renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1)
      )}
    </Menu.SubMenu>
  ) : (
    <Menu.Item
      key={item.id}
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}
    >
      {item.children
        ? item.children
        : renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
    </Menu.Item>
  );
};

//TODO: fix lai submenu
const renderMenu = (item, sidebarColorSet, isSidebarBgImage, index) => {
  // console.log("itemne", toJS(item));
  return item?.children ? (
    <Menu.SubMenu
      title={`${item.title}`}
      icon={item.icon}
      key={item.path ? item.path : item.id}
    >
      {item.children
        .filter((e) => {
          if (!settings.checkPermission) return true;
          else return e.isAccess;
        })
        .map((submMenu) => (
          <Menu.Item key={submMenu.path || submMenu.id} exact={true}>
            <Link to={item.path + "/" + submMenu.path}>{submMenu.title}</Link>
          </Menu.Item>
        ))}
    </Menu.SubMenu>
  ) : (
    <Menu.Item
      key={item.path}
      // exact={item.exact}
      exact={`${item.exact}`}
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}
    >
      {item.children
        ? item.children
        : renderMenuItemChildren(
          item,
          sidebarColorSet,
          isSidebarBgImage,
          index
        )}
    </Menu.Item>
  );
};

export const getRouteMenus = (accessRoutes) => {
  const { sidebarColorSet } = useSidebarContext();
  const { isSidebarBgImage } = useSidebarContext();
  // return routesConfig.map((route) =>
  //   renderMenu(route, sidebarColorSet, isSidebarBgImage, 0)
  // );

  let accessRouter = accessRoutes;

  accessRouter = accessRoutes.filter((route) => {
    return route.isAccess || !settings.checkPermission;
    // return true;
  });

  // console.log("accessRouter", JSON.stringify(accessRouter));

  return accessRouter.map((route) =>
    renderMenu(route, sidebarColorSet, isSidebarBgImage, 0)
  );
};
