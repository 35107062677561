import { productPackageApi } from "api/productPackage.api";
import { useState } from "react";
import { ProductPackage, ProductPackageQuery } from "types/productPackage";

export const useProductPackage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProductPackage[]>([]);
  const [total, setTotal] = useState(0);

  const fetchData = async (
    query: ProductPackageQuery,
    requiredProductPackages?: ProductPackage[]
  ) => {
    setLoading(true);
    const res = await productPackageApi.findAll(query);
    setLoading(false);

    let productPackages: ProductPackage[] = res.data.productPackages;
    if (requiredProductPackages) {
      requiredProductPackages.forEach((item) => {
        if (!productPackages.map((e) => e.id).includes(item.id)) {
          productPackages.push(item);
        }
      });
    }

    if (query?.limit && query.page) {
      productPackages.forEach((item, index) => {
        item.no = query?.limit * (query.page - 1) + index + 1;
      });
    }

    setData(productPackages);
    setTotal(res.data.total);
  };

  const clearProductPackage = () => {
    setData([]);
  };

  const setProductPackage = (data: ProductPackage[]) => {
    setData(data);
  };

  return {
    productPackages: data,
    productPackageLoading: loading,
    productPackagesTotal: total,
    fetchProductPackage: fetchData,
    clearProductPackage,
    setProductPackage,
  };
};
