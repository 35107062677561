import { Space } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";
import "./index.style.less";

const AppLogo = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <div className="app-logo">
      {hasSidebarColor && sidebarColorSet.mode === "dark" ? (
        <Link to={"/"}>
          <img src="/assets/images/logo-white-with-name.png" alt="crema-logo" />
        </Link>
      ) : (
        <>
          <Space size={1}>
            <Link to={"/"}>
              <img
                src={"/logo.png"}
                style={{ width: "auto", height: "40px" }}
              />
            </Link>
            {/* <Space direction="vertical" align="start" size={0}>
              <h2 style={{ marginBottom: "5px" }}>
                {process.env.REACT_APP_BRAND_NAME}
              </h2>
              <p style={{ marginBottom: 0 }}>Tra cứu đơn hàng</p>
            </Space>
              <p style={{ marginBottom: 0 }}>Quản trị viên</p>
            </Space> */}
          </Space>
        </>
      )}
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
